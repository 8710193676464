<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 작업기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-7d"
            defaultEnd="2d"
            label="LBL0000201"
            name="permitDates"
            v-model="searchParam.permitDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 신청부서 -->
          <c-dept 
            :isFirstValue="false"
            type="search" 
            label="LBL0000202" 
            name="applicationDeptCd" 
            v-model="searchParam.applicationDeptCd" 
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 승인부서 -->
          <c-dept 
            :isFirstValue="false"
            type="search" 
            label="승인부서" 
            name="issuedDeptCd" 
            v-model="searchParam.issuedDeptCd" 
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 허가서구분 -->
          <c-select
            type="search"
            codeGroupCd="SOP_PERMIT_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="permitTypeCd"
            label="LBL0000204"
            v-model="searchParam.permitTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 보충작업 -->
          <c-select
            type="search"
            codeGroupCd="SOP_SUPPLEMENT_WORK_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="supplementWorkTypeCd"
            label="LBL0000205"
            v-model="searchParam.supplementWorkTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행단계 -->
          <c-select
            type="search"
            stepperGrpCd="SWP_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="swpStepCd"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.swpStepCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-process
            label="공정"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 설비유형 -->
          <c-equip-class
            type="search"
            label="LBL0001720"
            name="equipmentTypeCd"
            :plantCd="searchParam.plantCd"
            :processCd="searchParam.processCd"
            v-model="searchParam.equipmentTypeCd">
          </c-equip-class>
        </div>
      </template>
    </c-search-box>
    <!-- 작업허가서 목록 -->
    <c-table
      title="LBL0000210"
      tableId="swp01"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 화기작업 등록 -->
          <c-btn v-if="editable" label="LBL0000207" icon="add" @btnClicked="addFire" /> 
          <!-- 일반작업 등록 -->
          <c-btn v-if="editable" label="LBL0000208" icon="add" @btnClicked="addNormal" />
          <!-- 검색 -->
          <c-btn label="LBL0000209" icon="search" @btnClicked="getList" />
          <!-- <c-btn label="test" icon="search" @btnClicked="test" /> -->
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'equipmentTypeName'">
          <q-chip
            outline square 
            color="blue"
            text-color="white"
            class="q-ma-none customchipdept">
            {{props.row['equipmentTypeName']}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safe-work-permit',
  data() {
    return {
      searchParam: {
        plantCd: null,
        permitDates: [],
        applicationDeptCd: null,
        issuedDeptCd: null,
        permitTypeCd: null,
        supplementWorkTypeCd: null,
        swpStepCd: null,
        processCd: '',
        equipmentTypeCd: '',
      },
      grid: {
        columns: [
          {
            // 사업장
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: 'LBL0000200',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
          {
            // 작업개요
            fix: true,
            name: 'workSummary',
            field: 'workSummary',
            label: 'LBL0000211',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:250px',
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비유형 / 설비명',
            align: 'left',
            style: 'width:400px',
            sortable: true,
          },
          {
            // 진행단계
            name: 'swpStepName',
            field: 'swpStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 결재진행단계
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: 'LBL0000212',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 허가일
            name: 'permitDate',
            field: 'permitDate',
            label: 'LBL0000213',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 허가서구분
            name: 'permitTypeName',
            field: 'permitTypeName',
            label: 'LBL0000204',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 보충작업
            name: 'supplementWorkTypeNames',
            field: 'supplementWorkTypeNames',
            label: 'LBL0000205',
            align: 'left',
            sortable: true,
            style: 'width:200px',
          },
          {
            // 작업업체
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            // 신청부서
            name: 'approvalDeptName',
            field: 'approvalDeptName',
            label: 'LBL0000202',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'relationCooperation2DeptName',
            field: 'relationCooperation2DeptName',
            // 승인부서
            label: '승인부서',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.workPermit.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    addFire() {
      // 화기 작업등록
      this.linkClick({ permitTypeCd: 'SPT0000005', })
    },
    addNormal() {
      // 일반 작업등록
      this.linkClick({ permitTypeCd: 'SPT0000001', })
    },
    linkClick(row, col, index) {
      // 작업허가서 상세
      this.popupOptions.title = 'LBL0000938';
      this.popupOptions.param = {
        sopWorkPermitId: row ? row.sopWorkPermitId : '',
        permitTypeCd: row ? row.permitTypeCd : '',
      };
      this.popupOptions.target = () => import(`${'./safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    // test() {
    //   this.$http.url = '/api/com/test';
    //   this.$http.type = 'GET';
    //   this.$http.param = {
    //     empNo: 'MDA3MzhFNTUyNTM5NEE2MkEyNTQ4OTY0OEVDRTNCRkRDN0M1MEVFOUU5RjUwRjA0MTg4ODVCMEM3M0RENkVBNDA0MUYwMEZDOTJGQzdDNEEyOTc2QjNBMUM0RjY3QzE1NTI0QkEyOEFFODMyQUMzMzg0MURGMTExNDhFMjFGQTIyMDdENDYwMEM2MkUyRDk3NUFFNDA3NDNGMDA1NTA3RkRENTEyQkRENkNGNTE0QkJCOTIyOUM3OERDRTk4QzEzNzA1MTU5RkRGRjU0NTkxQTdEMkRDREZFOEI2QTNGNzhDNEQyMjNGQTI4Q0Y0RjRCRkVEQjI1MzUwQkRGNUQ1N0VGRkY3OEFGOTNENzEwQUZDMDc2MjQxMzQwMzg0NjhEQTgxNUM2NzJEMzg0Njc5M0NEQ0NDODZFNUYwMDVFQTA3RkU4NkE1QjI4RkZDRDA3QUU5M0RBQkIwM0ZBMjIxRkZDRTExRjE2NjE4RDlDQUYxNUUxRDVGNzJENDJDRTlBOTYwQ0REQjg5QUNCNDBFRDE2M0M1RTJFMjM1QjU1ODkzNkMzMDQ0ODdDNDYyMUFFOTdDODRGQ0U1MDM0NjQ4NTJEQkY4NUY2Mzg5QzU0RDlDODI0QjM5RDU5Mzc3MTgyMzIzQUE2Q0NGMjk5QkM1OTdEMTg4QTMwNzgzOTc5Njk='
    //   };
    //   this.$http.request((_result) => {
    //     console.log(_result.data);
    //   },);

    // }
  }
};
</script>
